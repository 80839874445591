import React, { useState, useContext, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import TocIcon from '@mui/icons-material/Toc';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Box,
  Grid,
  Button,
  Container,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  styled,
  InputAdornment,
  SvgIcon,
  TextField,
  Tooltip
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { deleteApiReq, getApiReq, getReq, postApiReq, postReq } from 'src/utils/ApiHandler';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showToast } from 'src/redux/action';
import { AuthContext } from 'src/utils/AuthContext';
import Loader from './popup/Loader';
import moment from 'moment';
import Cookies from 'js-cookie';

const StyledTableCell = styled(TableCell)({
  backgroundColor: '#7b819d',
  boxShadow: 2,
  color: 'white',
  fontSize: '14px',
  padding: '5px 10px 5px 10px',
  textTransform: 'capitalize',
  fontWeight: '600'
});
const StyledBodyTableCell = styled(TableCell)({
  fontSize: '14px',
  padding: '15px 10px 15px 10px',
  fontWeight: '500'
});

export default function Users() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userList, setUserList] = useState();
  const [search, setSearch] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { user, type } = useContext(AuthContext);
  let sign = Cookies.get('sign');
  let userId = Cookies.get('user-data')
  userId = userId ? JSON.parse(userId) : null;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getWhiteListApproval();
  }, []);

  const getWhiteListApproval = () => {
    setIsLoading(true);
    getApiReq(`admin/get-whitelist-users`).then((res) => {
      if (res.status) {
        if (res.data.status === 1) {
          setUserList(res?.data?.data)
          setIsLoading(false);
        } else {
          dispatch(showToast({ type: 'error', message: res.data.error }));
        }
      } else {
        dispatch(showToast({ type: 'error', message: 'Something Went Wrong' }));
        setIsLoading(false);
      }
    });
  };


  const handleApprove = async (e, id, approve) => {
    e.preventDefault();

    const data = { id, approve };
    setIsLoading(true);
    postApiReq('admin/approve-whitelist-users', data).then((res) => {
      setIsLoading(false);
      if (res.status) {
        if (res.data.status === 1) {
          dispatch(showToast({ type: 'success', message: 'User Whitelisted Successfully!' }));
        } else {
          dispatch(showToast({ type: 'error', message: res.data.error }));
        }
      } else {
        dispatch(showToast({ type: 'error', message: 'Something Went Wrong' }));
        setIsLoading(false);
      }

    })

  }

  const connectMetaMaskWallet = async () => {
    if (!sign) {
      setIsLoading(true);
      /**check metamask install or not  */
      if (Boolean(ethereum && ethereum.isMetaMask)) {
        try {
          // Will open the MetaMask UI
          await ethereum
            .request({ method: "eth_requestAccounts" })
            .then((res) => {
              getReq(`admin/authenticate/${userId.id}/${res[0]}`)
                .then((authResponse) => {
                  if (authResponse.status) {
                    if (authResponse.data.status === 2) {
                      Cookies.remove('user');
                      Cookies.remove('user-data');
                      navigate('/login')
                      dispatch(showToast({
                        type: "error",
                        message: `Connect with Admin's Wallet Address`,
                      }))

                    } else {
                      ethereum
                        .request({
                          method: "personal_sign",
                          params: [`${authResponse.data.data}`, res[0]],
                        })
                        .then((sigRes) => {
                          if (sigRes) {
                            Cookies.set('sign', sigRes)
                            // dispatch(closeWalletModal());
                            postReq(`admin/authenticate`, {
                              address: res[0],
                              signature: sigRes,
                            })
                              .then((authTokenRes) => {
                                if (authTokenRes.status) {
                                  Cookies.set(
                                    "user", JSON.stringify({ token: authTokenRes.data.data.token }),

                                    { expires: 1 }
                                  );
                                  dispatch(
                                    showToast({
                                      type: "success",
                                      message: "Metamask wallet connect",
                                    })
                                  );
                                  handleApprove();
                                  // dispatch(setWalletId(res[0]));
                                  setIsLoading(false);
                                } else {
                                  dispatch(
                                    showToast({
                                      type: "error",
                                      message: authTokenRes.error,
                                    })
                                  );
                                  setIsLoading(false);
                                }
                              })
                              .catch((e) => {
                                setIsLoading(false);
                                dispatch(
                                  showToast({
                                    type: "error",
                                    message: e?.authResponse?.data,
                                  })
                                );
                              });
                          }
                        })
                        .catch((e) => {
                          setIsLoading(false);
                          dispatch(
                            showToast({ type: "error", message: e?.message })
                          );
                        });
                    }
                  } else {
                    setIsLoading(false);
                    dispatch(
                      showToast({ type: "error", message: authResponse.error })
                    );
                  }
                })
                .catch((e) => {
                  setIsLoading(false);
                  dispatch(
                    showToast({ type: "error", message: e?.authResponse?.data })
                  );
                });
            });
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      } else {
        dispatch(showToast({ type: "error", message: "Metamask Not Install" }));
        setIsLoading(false);
      }
    }
    else {
      handleApprove();
    }

  };


  useEffect(() => {
    getWhiteListApproval();
  }, []);

  return (
    <>
      <Loader isLoading={isLoading} />
      <div>
        <Container maxWidth="xl">
          <Box
            sx={{ pb: 5, display: 'flex', marginLeft: '-25px', justifyContent: 'space-between' }}
          >
            <Typography variant="h4">Users</Typography>
            <Box sx={{ marginBottom: '0px' }}>
              {/* <TextField
                fullWidth
                color="primary"
                InputProps={{
                  style: {
                    height: '40px',
                    padding: '0 10px',
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search"
                variant="outlined"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setOffset(0);
                }}
              /> */}
            </Box>

          </Box>
          <Grid container spacing={3}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell style={{ width: '8%' }}>S No.</StyledTableCell>
                      {/* <StyledTableCell style={{ width: '10%' }}>Image</StyledTableCell> */}
                      <StyledTableCell style={{ width: '7%' }}>Full Name</StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '15%' }}>
                        Email
                      </StyledTableCell>

                      <StyledTableCell align="center" style={{ width: '10%' }}>
                        Wallet Address
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '10%' }}>
                        Created At
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '20%' }}>
                        Action
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userList?.length > 0 ? (
                      userList
                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        ?.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            style={index % 2 ? { background: '#f0f1f4' } : { background: 'white' }}
                          >
                            <StyledBodyTableCell component="th" scope="row">
                              {index + 1}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell>
                              {row.user_full_name}
                            </StyledBodyTableCell>

                            <StyledBodyTableCell align="center">
                              {row.user_email}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              {row.wallet_address}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              {moment(row.created_at).format('DD MMM YYYY')}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">

                              <Button onClick={(e) => handleApprove(e, row.user_id, 1)} sx={{ width: '80px', minWidth: '40px' }}>
                                Approve
                              </Button>
                              <Button onClick={(e) => handleApprove(e, row.user_id, 0)} sx={{ width: '70px', minWidth: '40px' }}>
                                Reject
                              </Button>

                            </StyledBodyTableCell>

                          </TableRow>
                        ))
                    ) : (
                      <StyledBodyTableCell colSpan={9}>
                        <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                          No data found
                        </Typography>
                      </StyledBodyTableCell>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Container>
        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={userList?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
}
