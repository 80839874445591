import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Dialog } from "@mui/material";

const Loader = ({ isLoading }) => (
  <Dialog
    className="dialog"
    sx={{ color: "#fff", zIndex: 100000 }}
    open={isLoading ? isLoading : false}
  >
    <CircularProgress color="inherit" />
  </Dialog>
);
export default Loader;
