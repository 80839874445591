import React, { useRef, useState } from 'react';
import { styled, Button, TableCell, Grid, Container, Typography, Box, TextField, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { showToast } from 'src/redux/action';
import { useDispatch } from 'react-redux';
import { Stack } from '@mui/system';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import { getApiReq, getImageUrl, getMultipleImageUrl, getReq, postApiReq, postReq } from 'src/utils/ApiHandler';
import { ENV } from 'src/utils/env';
import image from '../assets/images/20.png'
import { PropertyValidation } from 'src/utils/Auth';
import { isYupError, parseYupError } from 'src/utils/Yup';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from './popup/Loader';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import { Editor } from '@tinymce/tinymce-react';

export const data = [
  ["Year", "Sales", "Expenses"],
  ["2004", 1000, 400],
  ["2005", 1170, 460],
  ["2006", 660, 1120],
  ["2007", 1030, 540],
];
export const options = {
  title: "Company Performance",
  curveType: "function",
  legend: { position: "bottom" },
};

const StyledTableCell = styled(TableCell)({
  backgroundColor: '#7b819d',
  boxShadow: 2,
  color: 'white',
  fontSize: '14px',
  padding: '15px 10px 15px 20px',
  textTransform: 'capitalize',
  fontWeight: '600',
  width: 'fit-content'
});
const StyledBodyTableCell = styled(TableCell)({
  fontSize: '14px',
  padding: '15px 10px 15px 20px',
  fontWeight: '500'
});

export default function AddProperty() {
  const dispatch = useDispatch();
  const descriptionRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null)
  const [form, setForm] = useState({
    title: '',
    description: '',
    valuation: '',
    rentalReturns: '',
    estimatedAppreciation: '',
    image: '',
    featuredImage: '',
    propertyCategory: '',
    totalFractions: '',
    propertyLocation: '',
    fractionPrice: '',
    minFractionBuy: '',
    maxFractionBuy: ''
  })

  let user = Cookies.get('user-data')
  user = user ? JSON.parse(user) : null;
  const queryParam = queryString.parse(location.pathname);

  const handleImageUpload = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const { name, files } = e.target;
      const file = files[0];
      if (file.size) {
        if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg') {
          const data = new FormData();
          data.append("file", file);
          const image = await getImageUrl(data);
          if (image) {
            handleChange({ target: { name: name, value: image } });
          }
        } else {
          // dispatch(showToast({ type: 'error', message: 'Please upload accepted file.' }));
          return false;
        }
      }
    }
  };

  const uploadMultipleImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const { name, files } = e.target;
      const file = files;
      if (file[0].size) {
        if (file.length <= 10) {
          if (file[0].type === 'image/png' || file[0].type === 'image/jpeg' || file[0].type === 'image/jpg') {
            const data = new FormData();
            for (let i = 0; i < file?.length; i++) {
              data.append("file", file[i]);
            }
            const image = await getMultipleImageUrl(data);
            if (image) {
              handleChange({ target: { name: name, value: image } });
            }
          } else {
            // dispatch(showToast({ type: 'error', message: 'Please upload accepted file.' }));
            return false;
          }
        }
        else {
          dispatch(showToast({ type: 'error', message: 'Only 10 files are acceptable.' }))
        }
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
    setErrors({
      ...errors,
      [name]: ''
    })
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const description = descriptionRef.current.getContent();
    let data = {
      title: form.title,
      description: description,
      valuation: Number(form.valuation),
      rentalReturns: Number(form.rentalReturns),
      estimatedAppreciation: Number(form.estimatedAppreciation),
      propertyImages: form.image,
      featuredImage: form.featuredImage,
      propertyCategory: form.propertyCategory,
      totalFractions: Number(form.totalFractions),
      propertyLocation: form.propertyLocation,
      fractionPrice: Number(form.fractionPrice),
      minFractionBuy: Number(form.minFractionBuy),
      maxFractionBuy: Number(form.maxFractionBuy)
    }
    try {
      // setErrors({});
      // await PropertyValidation.validate(form, {
      //   abortEarly: false,
      // });
      setIsLoading(true);
      postApiReq('admin/add-property', data).then((res) => {
        setIsLoading(false)
        if (res.status) {
          if (res.data.status === 0) {
            dispatch(showToast({ type: 'success', message: res.data.error }))
          }
          else {
            dispatch(showToast({ type: 'success', message: 'Property added successfully.' }));
            navigate('/dashboard/app');
          }
        } else {
          dispatch(showToast({ type: 'error', message: res.error }));
          setIsLoading(false)
        }
      })
        .catch((e) => {
          console.log('error', e);
        });
    }
    catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.log('error');
      }
    }
  };

  const handleSelectCategory = (event) => {
    setForm({ ...form, propertyCategory: event.target.value });
    // setErrors({ ...errors, category_id: '' })
  };
  //   setIsLoading(true);
  //   /**check metamast install or not  */
  //   if (Boolean(ethereum && ethereum.isMetaMask)) {
  //     try {
  //       // Will open the MetaMask UI
  //       await ethereum
  //         .request({ method: "eth_requestAccounts" })
  //         .then((res) => {
  //           getReq(`admin/authenticate/${user.id}/${res[0]}`)
  //             .then((authResponse) => {
  //               console.log({ authResponse });
  //               if (authResponse.status) {
  //                 ethereum
  //                   .request({
  //                     method: "personal_sign",
  //                     params: [`${authResponse.data.data}`, res[0]],
  //                   })
  //                   .then((sigRes) => {
  //                     if (sigRes) {
  //                       // dispatch(closeWalletModal());
  //                       postReq(`admin/authenticate`, {
  //                         address: res[0],
  //                         signature: sigRes,
  //                       })
  //                         .then((authTokenRes) => {
  //                           if (authTokenRes.status) {
  //                             Cookies.set(
  //                               "user", JSON.stringify({ token: authTokenRes.data.data.token }),

  //                               { expires: 1 }
  //                             );
  //                             dispatch(
  //                               showToast({
  //                                 type: "success",
  //                                 message: "Metamask wallet connect",
  //                               })
  //                             );
  //                             // navigate(`${queryParam?.referrer || '/login'}`);
  //                             // dispatch(setWalletId(res[0]));
  //                             setIsLoading(false);
  //                           } else {
  //                             dispatch(
  //                               showToast({
  //                                 type: "error",
  //                                 message: authTokenRes.error,
  //                               })
  //                             );
  //                             setIsLoading(false);
  //                           }
  //                         })
  //                         .catch((e) => {
  //                           setIsLoading(false);
  //                           dispatch(
  //                             showToast({
  //                               type: "error",
  //                               message: e?.authResponse?.data,
  //                             })
  //                           );
  //                         });
  //                     }
  //                   })
  //                   .catch((e) => {
  //                     setIsLoading(false);
  //                     dispatch(
  //                       showToast({ type: "error", message: e?.message })
  //                     );
  //                   });
  //               } else {
  //                 setIsLoading(false);
  //                 dispatch(
  //                   showToast({ type: "error", message: authResponse.error })
  //                 );
  //               }
  //             })
  //             .catch((e) => {
  //               setIsLoading(false);
  //               dispatch(
  //                 showToast({ type: "error", message: e?.authResponse?.data })
  //               );
  //             });
  //         });
  //     } catch (error) {
  //       console.error(error);
  //       setIsLoading(false);
  //     }
  //   } else {
  //     dispatch(showToast({ type: "error", message: "Metamask Not Install" }));
  //     setIsLoading(false);
  //   }
  // };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Container>
        <Grid item xs={12} sm={12} md={12}>
          <Box
            sx={{ pb: 5, display: 'flex', marginLeft: '-25px', justifyContent: 'space-between' }}
          >
            <Typography variant="h4">Add Property</Typography>
            <Box>
              <Button variant="contained" to="#" onClick={() => navigate(-1)}>
                Back
              </Button>
            </Box>
          </Box>
          <Stack spacing={2}>
            <Typography>Feature Image</Typography>
            <div className="contest_image">
              <label style={{ width: '100%', height: '100%', borderRadius: '10px', border: '1px solid #dde0e4', display: 'flex', alignItems: 'center', justifyContent: "center" }} htmlFor="show_feature_image">
                {!form.featuredImage ?
                  <div className='label_image'>
                    <FolderOpenOutlinedIcon /><span>Choose File</span>
                  </div> :
                  <img style={{ cursor: 'pointer', width: '100%', height: '100%', borderRadius: '10px', objectFit: 'cover' }} src={form.featuredImage ? `${ENV.BASE_URL}/property_image/${form.featuredImage}` : image} alt="" />

                }
              </label>
              <input
                id="show_feature_image"
                type="file"
                hidden
                name="featuredImage"
                onChange={handleImageUpload}
                alt=""
              />

            </div>
            {errors && <Box sx={{ color: 'red' }}>{errors.featuredImage}</Box>}
            <Typography>Image</Typography>
            <div className="contest_image">
              <label style={{ width: '100%', height: '100%', borderRadius: '10px', border: '1px solid #dde0e4', display: 'grid', placeItems: 'center' }} htmlFor="choose_image">
                {!form.image ?
                  <div className='label_image'>
                    <FolderOpenOutlinedIcon /><span>Choose File</span>
                  </div> :
                  <Stack direction='row' flexWrap='wrap' gap={4} padding={4}>
                    <img style={{ cursor: 'pointer', width: '150px', height: '100px', borderRadius: '10px', objectFit: 'cover' }} src={form.image[0] ? `${ENV.BASE_URL}/property_image/${form.image[0]}` : image} alt="" />
                    <img style={{ cursor: 'pointer', width: '150px', height: '100px', borderRadius: '10px', objectFit: 'cover' }} src={form.image[1] ? `${ENV.BASE_URL}/property_image/${form.image[1]}` : image} alt="" />
                    <img style={{ cursor: 'pointer', width: '150px', height: '100px', borderRadius: '10px', objectFit: 'cover' }} src={form.image[2] ? `${ENV.BASE_URL}/property_image/${form.image[2]}` : image} alt="" />
                    <img style={{ cursor: 'pointer', width: '150px', height: '100px', borderRadius: '10px', objectFit: 'cover' }} src={form.image[3] ? `${ENV.BASE_URL}/property_image/${form.image[3]}` : image} alt="" />
                    <img style={{ cursor: 'pointer', width: '150px', height: '100px', borderRadius: '10px', objectFit: 'cover' }} src={form.image[4] ? `${ENV.BASE_URL}/property_image/${form.image[4]}` : image} alt="" />
                    <img style={{ cursor: 'pointer', width: '150px', height: '100px', borderRadius: '10px', objectFit: 'cover' }} src={form.image[5] ? `${ENV.BASE_URL}/property_image/${form.image[5]}` : image} alt="" />
                    <img style={{ cursor: 'pointer', width: '150px', height: '100px', borderRadius: '10px', objectFit: 'cover' }} src={form.image[6] ? `${ENV.BASE_URL}/property_image/${form.image[6]}` : image} alt="" />
                    <img style={{ cursor: 'pointer', width: '150px', height: '100px', borderRadius: '10px', objectFit: 'cover' }} src={form.image[7] ? `${ENV.BASE_URL}/property_image/${form.image[7]}` : image} alt="" />
                    <img style={{ cursor: 'pointer', width: '150px', height: '100px', borderRadius: '10px', objectFit: 'cover' }} src={form.image[8] ? `${ENV.BASE_URL}/property_image/${form.image[8]}` : image} alt="" />
                    <img style={{ cursor: 'pointer', width: '150px', height: '100px', borderRadius: '10px', objectFit: 'cover' }} src={form.image[9] ? `${ENV.BASE_URL}/property_image/${form.image[9]}` : image} alt="" />
                  </Stack>
                }
              </label>
              <input
                id="choose_image"
                type="file"
                hidden
                name="image"
                onChange={(e) => uploadMultipleImage(e, 'image')}
                alt=""
                multiple
              />

            </div>
            {errors && <Box sx={{ color: 'red' }}>{errors.propertyImages}</Box>}

            <FormControl>
              <InputLabel id="demo-simple-select-disabled-label">Please select Category</InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={form.propertyCategory}
                error={errors?.propertyCategory}
                label="Please select Category"
                name="category_id"
                onChange={handleSelectCategory}
              >
                <MenuItem value="Economic">Economic</MenuItem>
                <MenuItem value="Comfort">Comfort</MenuItem>
                <MenuItem value="VIP">VIP</MenuItem>
              </Select>
            </FormControl>
            {errors && <Box sx={{ color: 'red' }}>{errors.propertyCategory}</Box>}
            <TextField
              fullWidth
              autoComplete="title"
              type="text"
              name="title"
              id="title"
              label="Title"
              value={form.title}
              error={errors?.title}
              onChange={handleChange}
            />
            {errors && <Box sx={{ color: 'red' }}>{errors.title}</Box>}
            {/* <TextField
              name="description"
              id="description"
              label="Description"
              variant="outlined"
              multiline
              rows={4}
              value={form.description}
              error={errors?.description}
              onChange={handleChange}
            /> */}
            <Editor
              onInit={(evt, editor) => descriptionRef.current = editor}
              initialValue=""
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
            />
            {errors && <Box sx={{ color: 'red' }}>{errors.description}</Box>}
            <TextField
              fullWidth
              autoComplete="valuation"
              type="text"
              name="valuation"
              id="valuation"
              label="Valuation"
              value={form.valuation}
              error={errors?.valuation}
              onChange={handleChange}
            />
            {errors && <Box sx={{ color: 'red' }}>{errors.valuation}</Box>}
            <TextField
              fullWidth
              autoComplete="rentalReturns"
              type="text"
              name="rentalReturns"
              id="rentalReturns"
              label="Rental"
              value={form.rentalReturns}
              error={errors?.rentalReturns}
              onChange={handleChange}
            />
            {errors && <Box sx={{ color: 'red' }}>{errors.rentalReturns}</Box>}
            <TextField
              fullWidth
              autoComplete="estimatedAppreciation"
              type="text"
              name="estimatedAppreciation"
              id="estimatedAppreciation"
              label="Estimated Appreciation"
              value={form.estimatedAppreciation}
              error={errors?.estimatedAppreciation}
              onChange={handleChange}
            />
            {errors && <Box sx={{ color: 'red' }}>{errors.estimatedAppreciation}</Box>}
            <TextField
              fullWidth
              autoComplete="totalFraction"
              type="text"
              name="totalFractions"
              id="totalFractions"
              label="Total Fractions"
              value={form.totalFractions}
              error={errors?.totalFractions}
              onChange={handleChange}
            />
            {errors && <Box sx={{ color: 'red' }}>{errors.totalFractions}</Box>}
            <TextField
              fullWidth
              autoComplete="propertyLocation"
              type="text"
              name="propertyLocation"
              id="propertyLocation"
              label="Property Location"
              value={form.propertyLocation}
              error={errors?.propertyLocation}
              onChange={handleChange}
            />
            {errors && <Box sx={{ color: 'red' }}>{errors.propertyLocation}</Box>}
            <TextField
              fullWidth
              autoComplete="fractionPrice"
              type="text"
              name="fractionPrice"
              id="fractionPrice"
              label="Fraction Price"
              value={form.fractionPrice}
              error={errors?.fractionPrice}
              onChange={handleChange}
            />
            {errors && <Box sx={{ color: 'red' }}>{errors.totalFractions}</Box>}
            <TextField
              fullWidth
              autoComplete="minFractionBuy"
              type="text"
              name="minFractionBuy"
              id="minFractionBuy"
              label="minFractionBuy"
              value={form.minFractionBuy}
              error={errors?.minFractionBuy}
              onChange={handleChange}
            />
            {errors && <Box sx={{ color: 'red' }}>{errors.minFractionBuy}</Box>}
            <TextField
              fullWidth
              autoComplete="maxFractionBuy"
              type="text"
              name="maxFractionBuy"
              id="maxFractionBuy"
              label="maxFractionBuy"
              value={form.maxFractionBuy}
              error={errors?.maxFractionBuy}
              onChange={handleChange}
            />
            {errors && <Box sx={{ color: 'red' }}>{errors.maxFractionBuy}</Box>}
          </Stack>
          <Box mt={2}>
            <Button
              fullWidth
              size="large"
              mt={2}
              type="submit"
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
          {/* <Box mt={2}>
            <Button
              fullWidth
              size="large"
              mt={2}
              type="submit"
              variant="contained"
              onClick={connectMetaMaskWallet}
            >
              Connect wallet
            </Button>
          </Box> */}
        </Grid>
      </Container>

    </>
  );
}
