import React, { useEffect, useState } from 'react'
import { Box, Container, Grid, Paper, Table, TableContainer, TableHead, TableRow, Typography, styled, TableCell, TableBody, TablePagination, Button, Modal } from '@mui/material'
import { useDispatch } from 'react-redux';
import { getApiReq } from 'src/utils/ApiHandler';
import Loader from './popup/Loader'
import { showToast } from 'src/redux/action';
import moment from 'moment';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ENV } from 'src/utils/env';

const CustomerBankDetails = () => {

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(null);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleOpen = (index) => {
    setOpen(true);
    setModalIndex(index);
  };
  const handleClose = () => setOpen(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 2,
  };

  const StyledTableCell = styled(TableCell)({
    backgroundColor: '#7b819d',
    boxShadow: 2,
    color: 'white',
    fontSize: '14px',
    padding: '5px 10px 5px 10px',
    textTransform: 'capitalize',
    fontWeight: '600'
  });
  const StyledBodyTableCell = styled(TableCell)({
    fontSize: '14px',
    padding: '15px 10px 15px 10px',
    fontWeight: '500'
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const getCustomerDetails = async () => {
    setIsLoading(true);
    getApiReq('admin/get-customer-bank-details').then((res) => {
      setIsLoading(false);
      if (res.status) {
        if (res.data.status === 1) {
          setCustomerDetails(res.data.data);
        }
        else {
          dispatch(showToast({ type: 'error', message: res.data.error }));
        }
      }
      else {
        dispatch(showToast({ type: 'error', message: 'System Error' }));
      }
    })
  }

  useEffect(() => {
    getCustomerDetails();
  }, [])


  return (
    <>
      <Loader isLoading={isLoading} />
      <div>
        <Container maxWidth="xl">
          <Box
            sx={{ pb: 5, display: 'flex', marginLeft: '-25px', justifyContent: 'space-between' }}
          >
            <Typography variant="h4">Customer Bank Details</Typography>
            <Box sx={{ marginBottom: '0px' }}>
            </Box>

          </Box>
          <Grid container spacing={3}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell style={{ width: '8%' }}>S No.</StyledTableCell>
                      {/* <StyledTableCell style={{ width: '10%' }}>Image</StyledTableCell> */}
                      <StyledTableCell style={{ width: '14%' }}>Account Holder Name</StyledTableCell>
                      <StyledTableCell style={{ width: '8%' }}>Bank Name</StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '15%' }}>
                        Account Number
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '10%' }}>
                        IFSC Code
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '10%' }}>
                        Cheque
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '10%' }}>
                        Created At
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customerDetails?.length > 0 ? (
                      customerDetails
                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        ?.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            style={index % 2 ? { background: '#f0f1f4' } : { background: 'white' }}
                          >
                            <StyledBodyTableCell component="th" scope="row">
                              {index + 1}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell>
                              {row.account_holder_name}
                            </StyledBodyTableCell>

                            <StyledBodyTableCell align="center">
                              {row.bank_name}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              {row?.account_no}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              {row.bank_ifsc_code}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              {/* {row.bank_ifsc_code} */}

                              <Button onClick={() => handleOpen(index)}><RemoveRedEyeIcon /></Button>
                              <Modal
                                open={open && index === modalIndex}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box sx={style}>
                                  <img src={row.cheque_image ? `${ENV.BASE_URL}/bank_verification/${row.cheque_image}` : null} />
                                </Box>
                              </Modal>

                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              {moment(row.created_at).format('DD MMM YYYY')}
                            </StyledBodyTableCell>
                          </TableRow>
                        ))
                    ) : (
                      <StyledBodyTableCell colSpan={9}>
                        <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                          No data found
                        </Typography>
                      </StyledBodyTableCell>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Container>
        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={customerDetails?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  )
}

export default CustomerBankDetails