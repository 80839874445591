import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Register from './pages/Register';
import NotFound from './pages/Page404';
import { ForgotPassword } from './pages/ForgotPassword';
import { LoginForm } from './components/authentication/login';
import { ResetPassword } from './pages/ResetPassword';
import PropertyList from './pages/PropertyList';
import EditProperty from './pages/EditProperty';
import AddProperty from './pages/AddProperty';
import UserBankApproval from './pages/UserBankApproval';
import Setting from './pages/Setting';
import User from './pages/Users';
import Transactions from './pages/Transactions';
import CustomerBankDetails from './pages/CustomerBankDetails';
import Orders from './pages/Orders';
import Cookies from 'js-cookie';
import PaymentRequest from './pages/PaymentRequest';
import Vault from './pages/Vault';
import VaultTransactions from './pages/VaultTransactions';

export default function Router() {
  let user = Cookies.get('user');
  user = user ? JSON.parse(user) : null
  return (
    useRoutes([
      {
        path: '/',
        element: !user ? <LogoOnlyLayout /> : <Navigate to="/dashboard/app" />,
        children: [
          { path: 'login', element: <LoginForm /> },
          { path: 'forgot-password', element: <ForgotPassword /> },
          { path: 'admin/change-password', element: <ResetPassword /> },
          { path: 'register', element: <Register /> },
          { path: '404', element: <NotFound /> },
          { path: '/', element: <Navigate to="/login" /> },
          { path: '*', element: <Navigate to="/404" /> }
        ]
      },
      {
        path: '/dashboard',
        element: user ? <DashboardLayout /> : <Navigate to="/login" />,
        children: [
          { element: <Navigate to="/login" replace /> },
          { path: 'add-property', element: <AddProperty /> },
          { path: 'setting', element: <Setting /> },
          { path: 'app', element: <PropertyList /> },
          { path: 'users', element: <User /> },
          { path: 'transactions', element: <Transactions /> },
          { path: 'edit-property/:id', element: <EditProperty /> },
          { path: 'user-bank-approval', element: <UserBankApproval /> },
          { path: 'orders', element: <Orders /> },
          { path: 'customer-bank-details', element: <CustomerBankDetails /> },
          { path: 'payment-request', element: <PaymentRequest /> },
          { path: 'vault', element: <Vault /> },
          { path: 'vault-transactions', element: <VaultTransactions /> }
        ]
      },
      { path: '*', element: <Navigate to="/404" replace /> }
    ])
  );
}
