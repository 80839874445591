import React, { useEffect, useState } from 'react'
import { Box, Button, Container, Grid, Switch, TextField, Typography } from '@mui/material'
import { getApiReq, postApiReq } from 'src/utils/ApiHandler'
import Loader from './popup/Loader'
import { useDispatch } from 'react-redux'
import { showToast } from 'src/redux/action'
import { useNavigate } from 'react-router-dom';
import { ENV } from 'src/utils/env'

const Vault = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Swal = require("sweetalert2");

  const [value, setValue] = useState({
    'IRATokens': 0,
    'USDTTokens': 0,
    'exchangeRate': 0
  })
  const [exchangeRate, setExchangeRate] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [addIRAToken, setAddIRAToken] = useState({
    'amount': 0,
    'address': ''
  })

  const [withDrawUSDTToken, setWithDrawUSDTToken] = useState({
    'amount': 0,
    'address': ''
  })

  const [withDrawIRAToken, setWithDrawIRAToken] = useState({
    'amount': 0,
    'address': ''
  })


  const getValues = async () => {
    getApiReq('admin/get-vault-ira-balance').then((res) => {
      setIsLoading(false);
      if (res.status) {
        if (res.data.status === 1) {
          setValue({ 'IRATokens': res?.data?.data?.IRA, 'USDTTokens': res?.data?.data?.ExchangeTokens, 'exchangeRate': res?.data?.data?.ExchangeRate })
          setExchangeRate(0);
        }
        else {
          console.error(res.data.error);
        }
      } else {
        console.error('Something Went Wrong');
      }
    })

  }

  const updateExchangeRate = async (e) => {
    if (exchangeRate > 0) {
      setIsLoading(true)
      e.preventDefault();
      const data = { exchangeRate }
      postApiReq('admin/update-exchange-token-rate', data).then((res) => {
        setIsLoading(false)
        if (res.status) {
          if (res.data.status === 1) {
            dispatch(showToast({ type: 'success', message: 'Exchange Rate has been changed Successfully' }))
            getValues();
          }
          else {
            dispatch(showToast({ type: 'error', message: res.data.error }));
          }
        }
        else {
          dispatch(showToast({ type: 'error', message: 'Something Went Wrong' }));
        }
      })
    } else {
      dispatch(showToast({ type: 'error', message: 'Rate must be more than zero.' }))
    }

  }

  const handleAddIRAToken = async (e) => {
    if (addIRAToken.amount > 0) {
      setIsLoading(true)
      e.preventDefault();
      const data = { amount: addIRAToken.amount };
      postApiReq('admin/add-ira-token', data).then((res) => {
        setIsLoading(false)
        if (res.status) {
          if (res.data.status === 1) {
            setAddIRAToken({ amount: 0 })
            dispatch(showToast({ type: 'success', message: 'IRA Token has been Added Successfully' }))
            getValues();
          }
          else {
            dispatch(showToast({ type: 'error', message: res.data.error }));
          }
        }
        else {
          dispatch(showToast({ type: 'error', message: 'Something Went Wrong' }));
        }
      })
    }
    else {
      dispatch(showToast({ type: 'error', message: 'All Fields are Mandatory' }));
    }


  }

  const handleWithdrawUSDTToken = async (e) => {
    if (withDrawUSDTToken.amount > 0 && withDrawUSDTToken.address !== "" && Number(withDrawUSDTToken.amount) <= Number(value.USDTTokens)) {
      setIsLoading(true)
      e.preventDefault();
      const data = { amount: withDrawUSDTToken.amount, address: withDrawUSDTToken.address };
      postApiReq('admin/withdraw-exchange-token-balance', data).then((res) => {
        setIsLoading(false)
        if (res.status) {
          if (res.data.status === 1) {
            setWithDrawUSDTToken({ amount: 0, address: '' })
            dispatch(showToast({ type: 'success', message: 'USDT Token has been Withdrawn Successfully' }))
            getValues();
          }
          else {
            dispatch(showToast({ type: 'error', message: res.data.error }));
          }
        }
        else {
          dispatch(showToast({ type: 'error', message: 'Something Went Wrong' }));
        }
      })
    }
    else if (Number(withDrawUSDTToken.amount) > Number(value.USDTTokens)) {
      dispatch(showToast({ type: 'error', message: 'Not Enough USDT Tokens' }));

    }
    else {
      dispatch(showToast({ type: 'error', message: 'All Fields are Mandatory' }))
    }

  }

  const handleWithdrawIRAToken = async (e) => {
    if (withDrawIRAToken.amount > 0 && withDrawIRAToken.address !== "" && Number(withDrawIRAToken.amount) <= Number(value.IRATokens)) {
      setIsLoading(true)
      e.preventDefault();
      const data = { amount: withDrawIRAToken.amount, address: withDrawIRAToken.address };
      postApiReq('admin/withdraw-ira-token-balance', data).then((res) => {
        setIsLoading(false)
        if (res.status) {
          if (res.data.status === 1) {
            setWithDrawIRAToken({ amount: 0, address: '' })
            dispatch(showToast({ type: 'success', message: 'IRA Token has been Withdrawn Successfully' }))
            getValues();
          }
          else {
            dispatch(showToast({ type: 'error', message: res.data.error }));
          }
        }
        else {
          dispatch(showToast({ type: 'error', message: 'Something Went Wrong' }));
        }
      })
    }
    else if (Number(withDrawIRAToken.amount) > Number(value.IRATokens)) {
      dispatch(showToast({ type: 'error', message: 'Not Enough IRA Tokens' }));

    }
    else {
      dispatch(showToast({ type: 'error', message: 'All Fields are Mandatory' }));
    }

  }

  useEffect(() => {
    getValues();
  }, [])


  return (
    <>
      <Loader isLoading={isLoading} />
      <Container>
        <Grid container rowSpacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '20px'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 5

                }}>
                <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center' }}>Vault</Typography>
                <Typography variant="body1" onClick={() => { window.open(`https://testnet.bscscan.com/address/${ENV.EXCHANGE}`) }} sx={{
                  display: 'flex', alignItems: 'center', cursor: 'pointer ', "&:hover": {
                    textDecoration: 'underline'
                  }
                }}>Address :- 0x34fA21B5AB37a36241AE6B8007636a7e1342B338</Typography>
              </Box>

              <Box>
                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => navigate('/dashboard/vault-transactions')}
                >
                  Transaction Details
                </Button>
              </Box>

            </Grid>

            <Grid container alignItems={'center'} gap={10} xs={12} sm={12} md={12}>
              <Grid item xs={3} sm={3} md={3}>
                <Typography paddingBottom={2} >Total IRA Tokens</Typography>
                <TextField
                  fullWidth
                  autoComplete="IRAToken"
                  type="text"
                  name="IRAToken"
                  id="IRAToken"
                  disabled
                  value={value.IRATokens}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3}>
                <Typography paddingBottom={2} >Total USDT Tokens</Typography>
                <TextField
                  fullWidth
                  autoComplete="IRAToken"
                  type="text"
                  name="IRAToken"
                  id="IRAToken"
                  disabled
                  value={value.USDTTokens}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3}>
                <Typography paddingBottom={2} >Current Exchange Rate</Typography>
                <TextField
                  fullWidth
                  autoComplete="IRAToken"
                  type="text"
                  name="IRAToken"
                  id="IRAToken"
                  disabled
                  value={value.exchangeRate}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Change Exchange Rate */}
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', marginTop: "20px" }}>Update Exchange Rate</Typography>
          <Grid container alignItems={'center'} justifyContent={'space-between'} gap={10} xs={12} sm={12} md={12}>
            <Grid item xs={4} sm={4} md={4} mt={2}>
              <Typography paddingBottom={2} paddingTop={2}>Change Exchange Rate</Typography>
              <TextField
                fullWidth
                autoComplete="platformFee"
                type="number"
                name="platformFee"
                id="platformFee"
                value={exchangeRate}
                onChange={(e) => { setExchangeRate(e.target.value) }}

              />

            </Grid>
            <Box mt={2}>
              <Button
                fullWidth
                size="large"
                mt={2}
                type="submit"
                variant="contained"
                onClick={updateExchangeRate}
              >
                Update Exchange Rate
              </Button>
            </Box>
          </Grid>



          {/* Add IRA Token */}
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', marginTop: "20px" }}>Add IRA Token</Typography>

          <Grid container alignItems={'end'} justifyContent={'space-between'} gap={10} xs={12} sm={12} md={12}>
            <Grid item xs={4} sm={4} md={4} mt={2}>
              <Typography paddingBottom={2} paddingTop={2}>Token Amount</Typography>
              <TextField
                fullWidth
                autoComplete="platformFee"
                type="number"
                name="platformFee"
                id="platformFee"
                value={addIRAToken.amount}
                onChange={(e) => { setAddIRAToken({ ...addIRAToken, amount: e.target.value }) }}
              />
            </Grid>

            <Box mt={2}>
              <Button
                fullWidth
                size="large"
                mt={2}
                type="submit"
                variant="contained"
                onClick={handleAddIRAToken}
              >
                Add IRA Tokens
              </Button>
            </Box>
          </Grid>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', marginTop: "20px" }}>Withdraw USDT Token</Typography>

          <Grid container alignItems={'center'} justifyContent={'space-between'} gap={10} xs={12} sm={12} md={12}>
            <Grid item xs={4} sm={4} md={4} mt={2}>
              <Typography paddingBottom={2} paddingTop={2}>Token Amount</Typography>
              <TextField
                fullWidth
                autoComplete="platformFee"
                type="number"
                name="platformFee"
                id="platformFee"
                value={withDrawUSDTToken.amount}
                onChange={(e) => { setWithDrawUSDTToken({ ...withDrawUSDTToken, amount: e.target.value }) }}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} mt={2}>
              <Typography paddingBottom={2} paddingTop={2}>Wallet Address</Typography>
              <TextField
                fullWidth
                autoComplete="platformFee"
                type="text"
                name="platformFee"
                id="platformFee"
                value={withDrawUSDTToken.address}
                onChange={(e) => { setWithDrawUSDTToken({ ...withDrawUSDTToken, address: e.target.value }) }}
              />
            </Grid>
            <Box mt={2}>
              <Button
                fullWidth
                size="large"
                mt={2}
                type="submit"
                variant="contained"
                onClick={handleWithdrawUSDTToken}
              >
                Withdraw USDT
              </Button>
            </Box>
          </Grid>

          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', marginTop: "20px" }}>Withdraw IRA Token</Typography>

          <Grid container alignItems={'center'} justifyContent={'space-between'} gap={10} xs={12} sm={12} md={12}>
            <Grid item xs={4} sm={4} md={4} mt={2}>
              <Typography paddingBottom={2} paddingTop={2}>Token Amount</Typography>
              <TextField
                fullWidth
                autoComplete="platformFee"
                type="number"
                name="platformFee"
                id="platformFee"
                value={withDrawIRAToken.amount}
                onChange={(e) => { setWithDrawIRAToken({ ...withDrawIRAToken, amount: e.target.value }) }}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} mt={2}>
              <Typography paddingBottom={2} paddingTop={2}>Wallet Address</Typography>
              <TextField
                fullWidth
                autoComplete="platformFee"
                type="text"
                name="platformFee"
                id="platformFee"
                value={withDrawIRAToken.address}
                onChange={(e) => { setWithDrawIRAToken({ ...withDrawIRAToken, address: e.target.value }) }}
              />
            </Grid>
            <Box mt={2}>
              <Button
                fullWidth
                size="large"
                mt={2}
                type="submit"
                variant="contained"
                onClick={handleWithdrawIRAToken}
              >
                Withdraw IRA
              </Button>
            </Box>

          </Grid>

        </Grid>

      </Container>

    </>
  )
}

export default Vault