import React, { useState, useEffect } from 'react';
import { styled, TableCell, TableBody, Grid, Container, Typography, Box, Paper, TableContainer, Table, TableHead, TableRow, TablePagination, TextField, Switch, Button } from '@mui/material';
import { getApiReq, getReq, postApiReq, postReq } from 'src/utils/ApiHandler';
import { showToast } from 'src/redux/action';
import { useDispatch } from 'react-redux';
import DnsIcon from '@mui/icons-material/Dns';
import ApartmentIcon from '@mui/icons-material/Apartment';
import moment from 'moment';
import { Stack } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import { SettingValidation } from 'src/utils/Auth';
import { isYupError, parseYupError } from 'src/utils/Yup';
import Cookies from 'js-cookie';

const StyledTableCell = styled(TableCell)({
  backgroundColor: '#7b819d',
  boxShadow: 2,
  color: 'white',
  fontSize: '14px',
  padding: '15px 10px 15px 20px',
  textTransform: 'capitalize',
  fontWeight: '600',
  width: 'fit-content'
});
const StyledBodyTableCell = styled(TableCell)({
  fontSize: '14px',
  padding: '15px 10px 15px 20px',
  fontWeight: '500'
});

export default function Setting() {
  const { id } = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState(null);
  const [checked, setChecked] = useState(true);
  const [IRAToken, setIRAToken] = useState(null);
  const [platformFee, setPlatFormFee] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  let sign = Cookies.get('sign');
  let userId = Cookies.get('user-data')
  userId = userId ? JSON.parse(userId) : null;

  const connectMetaMaskWallet = async () => {
    // if (!sign) {
    setIsLoading(true);
    /**check metamask install or not  */
    if (Boolean(window.ethereum && window.ethereum.isMetaMask)) {
      try {
        // Will open the MetaMask UI
        await window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((res) => {
            getReq(`admin/authenticate/${userId.id}/${res[0]}`)
              .then((authResponse) => {
                if (authResponse.status) {
                  if (authResponse.data.status === 2) {
                    Cookies.remove('user');
                    Cookies.remove('user-data');
                    navigate('/login')
                    dispatch(showToast({
                      type: "error",
                      message: `Connect with Admin's Wallet Address`,
                    }))

                  } else {
                    ethereum
                      .request({
                        method: "personal_sign",
                        params: [`${authResponse.data.data}`, res[0]],
                      })
                      .then((sigRes) => {
                        if (sigRes) {
                          Cookies.set('sign', sigRes)
                          // dispatch(closeWalletModal());
                          postReq(`admin/authenticate`, {
                            address: res[0],
                            signature: sigRes,
                          })
                            .then((authTokenRes) => {
                              if (authTokenRes.status) {
                                Cookies.set(
                                  "user", JSON.stringify({ token: authTokenRes.data.data.token }),

                                  { expires: 1 }
                                );
                                dispatch(
                                  showToast({
                                    type: "success",
                                    message: "Metamask wallet connect",
                                  })
                                );
                                handleApprove();
                                // dispatch(setWalletId(res[0]));
                                setIsLoading(false);
                              } else {
                                dispatch(
                                  showToast({
                                    type: "error",
                                    message: authTokenRes.error,
                                  })
                                );
                                setIsLoading(false);
                              }
                            })
                            .catch((e) => {
                              setIsLoading(false);
                              dispatch(
                                showToast({
                                  type: "error",
                                  message: e?.authResponse?.data,
                                })
                              );
                            });
                        }
                      })
                      .catch((e) => {
                        setIsLoading(false);
                        dispatch(
                          showToast({ type: "error", message: e?.message })
                        );
                      });
                  }
                } else {
                  setIsLoading(false);
                  dispatch(
                    showToast({ type: "error", message: authResponse.error })
                  );
                }
              })
              .catch((e) => {
                setIsLoading(false);
                dispatch(
                  showToast({ type: "error", message: e?.authResponse?.data })
                );
              });
          });
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    } else {
      dispatch(showToast({ type: "error", message: "Metamask Not Install" }));
      setIsLoading(false);
    }
    // }
    // else {
    // handleApprove();
    // }

  };


  const handleChange = (event) => {
    if (event.target.name === 'IRAToken') {
      setIRAToken(event.target.value);
      setErrors('')
    } else if (event.target.name === 'platformFee') {
      setPlatFormFee(event.target.value);
    }
    else {
      setChecked(event.target.checked);
    }
  };

  const getPropertySetting = async () => {
    getApiReq(`admin/get-property-settings`).then((res) => {
      if (res.status) {
        if (res.data.data) {
          setChecked(res.data?.data?.email_notification == 0 ? false : true);
          setIRAToken(res.data?.data?.ira_token_value);
          setPlatFormFee(res.data?.data?.platform_fee);
        }
        else {
          dispatch(showToast({ type: 'error', message: res.data.error }))
        }

      }
    }).catch((err) => {
      console.log('error', err)
    })
  }

  useEffect(() => {
    getPropertySetting();
  }, [])



  const handleSubmit = async () => {

    const { ethereum } = window;
    await ethereum.request({ method: "eth_requestAccounts", })

    let form = {
      IRAToken: IRAToken,
      platformFee
    }
    let data = {
      tokenValue: IRAToken,
      emailNotification: checked == true ? 1 : 0,
      platformFee
    }
    try {
      setErrors({});
      await SettingValidation.validate(form, {
        abortEarly: false,
      });
      postApiReq(`admin/update-property-settings`, data).then((res) => {
        if (res.status) {
          if (res.data.data) {
            dispatch(showToast({ type: 'success', message: 'Property Settings Updated Successfully.' }))
            getPropertySetting();
          }
          else {
            dispatch(showToast({ type: 'error', message: res.data.error }))
          }
        }
      })
        .catch((e) => {
          console.log('error', e);
        });
    }
    catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.log('error');
      }
    }
  }

  return (
    <>
      <Container>
        <Grid container rowSpacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '20px'
              }}
            >
              <Typography variant="h6" paddingBottom={'15px'} sx={{ display: 'flex', alignItems: 'center' }}>Settings</Typography>
            </Grid>

            <Grid container alignItems={'center'} xs={12} sm={12} md={12}>
              <Grid item xs={10} sm={10} md={10}>
                <Typography paddingBottom={2} >IRA Token Value (NINR)</Typography>
                <TextField
                  fullWidth
                  autoComplete="IRAToken"
                  type="text"
                  name="IRAToken"
                  id="IRAToken"
                  value={IRAToken}
                  error={errors?.IRAToken}
                  onChange={handleChange}
                />
                {errors && <Box sx={{ color: 'red' }}>{errors.IRAToken}</Box>}
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <Typography paddingBottom={2}>Email Notification</Typography>
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <Typography paddingBottom={2} paddingTop={2}>Platform Fee</Typography>
                <TextField
                  fullWidth
                  autoComplete="platformFee"
                  type="text"
                  name="platformFee"
                  id="platformFee"
                  value={platformFee}
                  error={errors?.platformFee}
                  onChange={handleChange}
                />
                {errors && <Box sx={{ color: 'red' }}>{errors.platformFee}</Box>}
              </Grid>
            </Grid>
            <Box mt={2}>
              <Button
                fullWidth
                size="large"
                mt={2}
                type="submit"
                variant="contained"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>

    </>
  );
}
