import React, { useState } from 'react';
import { useFormik, FormikProvider } from 'formik';
import { Stack, TextField, FormControl, Grid, Typography, Button, Paper } from '@mui/material';
import { getApiReq, postApiReq } from 'src/utils/ApiHandler';
import { useDispatch } from 'react-redux';
import { showToast } from 'src/redux/action';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import queryString from "query-string";

export const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { token,status } = window.location.href();
    // const { token,status } = useParams();
  const { token } = queryString.parse(window.location.search);
  const [form, setForm] = useState({
    password: '',
    confirmPassword:''
  });

  const handleSubmit = (e) => {
     if (form.password === form.confirmPassword) {
       postApiReq(`admin/change-password`, {
         "token":token,
         "password":form.password
      })
        .then((res) => {
          if (res.status) {
            dispatch(showToast({ type: 'success', message: 'Password updated successfully' }));
            navigate('/login')
          }
          else {
            dispatch(showToast({ type: 'error', message: res.error }));
          }
        })
    } else {
      dispatch(showToast({type:'error',message:'Password and Confirm password does not match.'}))
    }
  }

  return (
    <Paper variant="outlined" square>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={5}>
          <FormControl>
            <Typography mt={15} sx={{ fontSize: '40px', fontWeight: '600' }}>
              Reset Password
            </Typography>
            <Stack mt={4} spacing={3}>
              <TextField
                fullWidth
                autoComplete="password"
                type="password"
                label="Password"
                name="password"
                id="password"
                value={form.password}
                onChange={(e) => setForm({ ...form, password: e.target.value })}
              />
             <TextField
                fullWidth
                autoComplete="ConfirmPassword"
                type="password"
                label="Confirm Password"
                name="confirmPassword"
                id="confirmPassword"
                value={form.confirmPassword}
                onChange={(e) => setForm({ ...form, confirmPassword: e.target.value })}
              />
            </Stack>
            <Button style={{ marginTop: '25px' }} variant="contained" onClick={handleSubmit}>
             Submit
            </Button>
            <Button style={{ marginTop: '10px' }} variant="contained" href="/login">
              Cancel
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
};
