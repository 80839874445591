import { SET_CATEGORY, } from "../action/actionConstants";

const initialState = {
  parentCategories: [],
  selectedParentCategory: {}

};

const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORY:
      return action.payload
        ;
    default:
      return state;
  }
};

export default CategoryReducer;
