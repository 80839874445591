import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import moment from 'moment';
import { Button, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { getApiReq } from 'src/utils/ApiHandler';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function VaultTransactions() {
  const [value, setValue] = React.useState('1');
  const [isLoading, setIsLoading] = useState(false);
  const [saleTransactions, setSaleTransactions] = useState([]);
  const [iraDepositTransactions, setIraDepositTransactions] = useState([]);
  const [usdtWithDrawlTransactions, setUsdtWithDrawlTransactions] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterDate, setFilterDate] = useState(null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StyledTableCell = styled(TableCell)({
    backgroundColor: '#7b819d',
    boxShadow: 2,
    color: 'white',
    fontSize: '14px',
    padding: '5px 10px 5px 10px',
    textTransform: 'capitalize',
    fontWeight: '600'
  });
  const StyledBodyTableCell = styled(TableCell)({
    fontSize: '14px',
    padding: '15px 10px 15px 10px',
    fontWeight: '500'
  });

  const dateChange = (value) => {
    setFilterDate(moment(new Date(value)).format("YYYY-MM-DD"))
  };

  const getSalesTransactionDetails = async () => {
    getApiReq(`admin/get-ira-sales-transactions?filterDate=${filterDate}`).then((res) => {
      if (res.status) {
        if ((res.data.status === 1)) {
          setSaleTransactions(res.data.data);
        }
        else {
          console.error(res.data.error);
        }
      } else {
        console.log('Something Went Wrong');
      }
    })
  }

  const getIRADepositTransactionsDetails = async () => {
    getApiReq('admin/get-ira-deposit-transactions').then((res) => {
      if (res.status) {
        if ((res.data.status === 1)) {
          setIraDepositTransactions(res.data.data);
        }
        else {
          console.error(res.data.error);
        }
      } else {
        console.log('Something Went Wrong');
      }
    })
  }

  const getUSDTWithdrawlTransactionsDetails = async () => {
    getApiReq('admin/get-usdt-withdrawl-transactions').then((res) => {
      if (res.status) {
        if ((res.data.status === 1)) {
          setUsdtWithDrawlTransactions(res.data.data);
        }
        else {
          console.error(res.data.error);
        }
      } else {
        console.log('Something Went Wrong');
      }
    })
  }

  useEffect(() => {
    getSalesTransactionDetails();
    //   (async () => {
    //   await getIRADepositTransactionsDetails();
    //   await getUSDTWithdrawlTransactionsDetails();
    // })()
  }, [filterDate])


  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab onClick={() => { setFilterDate(null) }} label="IRA Sales Transaction" value="1" />
            <Tab onClick={() => getIRADepositTransactionsDetails()} label="IRA Deposit Transactions" value="2" />
            <Tab onClick={() => getUSDTWithdrawlTransactionsDetails()} label="USDT Withdrawl Transactions" value="3" />
          </TabList>
          {
            value === "1" &&
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={filterDate}
                onChange={(e) => dateChange(e)}
                closeOnSelect={true}
                renderInput={(props) => (
                  <TextField {...props} />
                )}
              />
            </LocalizationProvider>
          }
        </Box>
        <TabPanel value="1">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ width: '6%' }}>S No.</StyledTableCell>
                  {/* <StyledTableCell style={{ width: '10%' }}>Image</StyledTableCell> */}
                  <StyledTableCell style={{ width: '20%' }}>Token Transferred To</StyledTableCell>
                  <StyledTableCell align="center" style={{ width: '15%' }}>
                    Transaction Hash
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ width: '15%' }}>
                    USDT Recieved
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ width: '20%' }}>
                    IRA Transferred
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ width: '9%' }}>
                    Gas Used
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ width: '11%' }}>
                    Created At
                  </StyledTableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {saleTransactions?.length > 0 ? (
                  saleTransactions
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <StyledBodyTableCell component="th" scope="row">
                          {index + 1}
                        </StyledBodyTableCell>
                        <Tooltip title={row?.wallet_address} placement="top">
                          <StyledBodyTableCell>
                            {row?.wallet_address.slice(0, 20)}..
                          </StyledBodyTableCell>
                        </Tooltip>
                        <StyledBodyTableCell align="center" style={{ 'cursor': 'pointer' }} onClick={() => { window.open(`https://testnet.bscscan.com/tx/${row?.transaction_hash}`) }}>
                          {row?.transaction_hash.slice(0, 20)}..
                        </StyledBodyTableCell>
                        <StyledBodyTableCell align="center">
                          {row?.usdt_amount}
                        </StyledBodyTableCell>
                        <StyledBodyTableCell align="center">
                          {row?.ira_amount}
                        </StyledBodyTableCell>
                        <StyledBodyTableCell align="center">
                          {row?.gas_used}
                        </StyledBodyTableCell>
                        <StyledBodyTableCell align="center" >
                          {moment(row?.created_at).format('DD MMM YYYY')}
                        </StyledBodyTableCell>
                      </TableRow>
                    ))
                ) : (
                  <StyledBodyTableCell colSpan={9}>
                    <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                      No data found
                    </Typography>
                  </StyledBodyTableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={saleTransactions?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TabPanel>

        <TabPanel value="2">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ width: '5%' }}>S No.</StyledTableCell>
                  {/* <StyledTableCell style={{ width: '10%' }}>Image</StyledTableCell> */}
                  <StyledTableCell style={{ width: '15%' }}>IRA Amount Deposited</StyledTableCell>
                  <StyledTableCell align="center" style={{ width: '20%' }}>
                    Transaction Hash
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ width: '10%' }}>
                    Gas Used
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ width: '10%' }}>
                    Created At
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {iraDepositTransactions?.length > 0 ? (
                  iraDepositTransactions
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((row, index) => (
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <StyledBodyTableCell align="center" component="th" scope="row">
                          {index + 1}
                        </StyledBodyTableCell>
                        <StyledBodyTableCell align="center">
                          {row?.ira_amount}
                        </StyledBodyTableCell>
                        <StyledBodyTableCell align="center" style={{ 'cursor': 'pointer' }} onClick={() => { window.open(`https://testnet.bscscan.com/tx/${row?.transaction_hash}`) }}>
                          {row?.transaction_hash.slice(0, 45)}...
                        </StyledBodyTableCell>
                        <StyledBodyTableCell align="center">
                          {row?.gas_used}
                        </StyledBodyTableCell>
                        <StyledBodyTableCell align="center">
                          {moment(row?.created_at).format('DD MMM YYYY')}
                        </StyledBodyTableCell>
                      </TableRow>
                    ))
                ) : (
                  <StyledBodyTableCell colSpan={9}>
                    <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                      No data found
                    </Typography>
                  </StyledBodyTableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={iraDepositTransactions?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TabPanel>
        <TabPanel value="3">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ width: '8%' }}>S No.</StyledTableCell>
                  {/* <StyledTableCell style={{ width: '10%' }}>Image</StyledTableCell> */}
                  <StyledTableCell style={{ width: '7%' }}>USDT Transferred to</StyledTableCell>
                  <StyledTableCell align="center" style={{ width: '15%' }}>
                    Transaction Hash
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ width: '10%' }}>
                    Gas Used
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ width: '10%' }}>
                    USDT Amount
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ width: '10%' }}>
                    Created At
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usdtWithDrawlTransactions?.length > 0 ? (
                  usdtWithDrawlTransactions
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((row, index) => (
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <StyledBodyTableCell component="th" scope="row">
                          {index + 1}
                        </StyledBodyTableCell>
                        <Tooltip title={row?.wallet_address} placement="top">
                          <StyledBodyTableCell>
                            {row?.wallet_address?.slice(0, 20)}...
                          </StyledBodyTableCell>
                        </Tooltip>
                        <StyledBodyTableCell align="center" style={{ 'cursor': 'pointer' }} onClick={() => { window.open(`https://testnet.bscscan.com/tx/${row?.transaction_hash}`) }}>
                          {row?.transaction_hash?.slice(0, 20)}...
                        </StyledBodyTableCell>
                        <StyledBodyTableCell align="center">
                          {row?.gas_used}
                        </StyledBodyTableCell>
                        <StyledBodyTableCell align="center">
                          {row?.usdt_amount}
                        </StyledBodyTableCell>
                        <StyledBodyTableCell align="center">
                          {moment(row?.created_at).format('DD MMM YYYY')}
                        </StyledBodyTableCell>
                      </TableRow>
                    ))
                ) : (
                  <StyledBodyTableCell colSpan={9}>
                    <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                      No data found
                    </Typography>
                  </StyledBodyTableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={usdtWithDrawlTransactions?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}