/* Notification toast*/
export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TEST";

/* Homepage top NFT*/
export const SET_TOP_CATEGORY = "SET_TOP_CATEGORY";
export const SET_TOP_CREATORS = "SET_TOP_CREATORS";
export const SET_TOP_TRENDING = "SET_TOP_TRENDING";
export const SET_TOP_BLOG = "SET_TOP_BLOG";

/** Get Homepage top NFT */
export const GET_TOP_CATEGORY = "GET_TOP_CATEGORY";
export const GET_TOP_CREATORS = "GET_TOP_CREATORS";
export const GET_TOP_TRENDING = "GET_TOP_TRENDING";
export const GET_TOP_BLOG = "GET_TOP_BLOG";

/**Wallet Account */
export const SET_METAMAST_WALLET_INFO = "SET_METAMAST_WALLET_INFO";
export const SET_COINBASE_WALLET_INFO = "SET_COINBASE_WALLET_INFO";

/**Category */

export const SET_CATEGORY = "SET_CATEGORY"

export const SET_USER_DETAIl="SET_USER_DETAIl"