import { matches, max } from 'lodash';
import { options } from 'numeral';
import * as yup from 'yup';

export const LoginValidation = yup.object().shape({
  email: yup.string().required('Please enter email address.')
    .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Please enter valid email.'),
  password: yup.string().required('Please enter password.')
})

export const PropertyValidation = yup.object().shape({
  title: yup.string().required('Please enter contest name.'),
  // image: yup.string().required('Please select image.').nullable(),
  description: yup.string().required('Please enter Description'),
  featuredImage: yup.string().required('Please Select Feature imgae.').nullable(),
  valuation: yup.string().required('Please enter valuation.')
    .matches(/[0-9]/, 'Please enter number.'),
  rentalReturns: yup.string().required('Please enter rentalReturns.')
    .matches(/[0-9]/, 'Please enter number.'),
  estimatedAppreciation: yup.string().required('Please enter estimatedAppreciation.')
    .matches(/[0-9]/, 'Please enter number.'),
  propertyCategory: yup.string().required('Please select category.').nullable(),
  propertyLocation: yup.string().required('Please enter location.').nullable(),
  fractionPrice: yup.string().required('Please enter fraction price.').nullable()
    .matches(/[0-9]/, 'Please enter number.'),
  minFractionBuy: yup.string().required('Please enter min fraction buy.').nullable()
    .matches(/[0-9]/, 'Please enter number.'),
  maxFractionBuy: yup.string().required('Please enter max fraction buy.').nullable()
    .matches(/[0-9]/, 'Please enter number.'),
  totalFractions: yup.string().required('Please enter total fraction.').nullable()
    .matches(/[0-9]/, 'Please enter number.'),
})

export const SettingValidation = yup.object().shape({
  IRAToken: yup.string().required('Please enter token value.').nullable()
    .matches(/[0-9]/, 'Only numbers are acceptable.'),
  platformFee: yup.string().required('Please Enter Platform Fee')
    .matches(/[0-9]/, 'Only numbers are acceptable.')
})

export const UserValidation = yup.object().shape({
  username: yup.string().required('Please enter content.'),
  email: yup.string().required('Please enter email address.')
    .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Please enter valid email.'),
  password: yup.string().required('Please enter password.')
    // .matches(/^(?=.*[A-Z])(?=.*[~!@#$%^&*()/_=+[\]{}|;:,.<>?-])(?=.*[0-9])(?=.*[a-z]).{8,14}$/, 'Only accept One Uppercase and atleast one special characters and numbers.')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/, 'Only accept One Uppercase and one lowercase and atleast one special characters and numbers.')
    .min(8, 'Minimum 8 characters is required.'),
  phone: yup.string().required('Please enter phone number.')
    // .matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, 'Please enter only 10 digit number.'),
    .matches(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/, 'Please enter valid mobile number.')
    .min(10, 'Minimum 10 numbers are acceptable.')
    .max(13, 'Maximum 13 numbers are acceptable.'),
  user: yup.string().required('Please select user.'),
  image: yup.string().required('Please enter image.')
})

