import axios from "axios";
import { ENV } from "../../src/utils/env";
import Cookies from 'js-cookie';

const responseFormatter = (status, data, error) => {
  return { status, data, error };
}

export const postApiReq = async (url, data) => {
  const route_url = ENV.BASE_URL + url;
  var USER = null
  if (Cookies.get('user')) {
    USER = JSON.parse(Cookies.get('user'))
  }
  return await axios.post(route_url, data, {
    headers: USER?.token ? {
      Accept: "application/json",
      Authorization: `Bearer ${USER.token}`,
    } :
      {
        Accept: "application/json",
      },
  })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch(e => {
      if (e.response?.data?.length > 0) {
        return responseFormatter(false, null, e.response.data)
      } else {
        return responseFormatter(false, null, e.response.data)
      }
    });
}

export const getApiReq = async (url) => {
  var USER = null
  if (Cookies.get('user')) {
    USER = JSON.parse(Cookies.get('user'))
  }
  const route_url = ENV.BASE_URL + url;
  return await axios.get(route_url,
    {
      headers: USER?.token ? {
        Accept: "application/json",
        Authorization: `Bearer ${USER.token}`,
      } :
        {
          Accept: "application/json",
        },
    })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch(e => {
      if (e.response.status === 403) {
        localStorage.removeItem('user');
        window.location.href = '/'
      }
      else
        if (e.response.data?.length > 0) {
          return responseFormatter(false, null, e.response.data)
        } else {
          return responseFormatter(false, null, e.response.data)
        }
    });
}

export const postReq = async (url, data) => {
  const route_url = ENV.BASE_URL + url;
  return await axios.post(route_url, data, {
    headers: {
      // Referer: "http://www.xyz.com",
      Accept: "application/json",
    },
  })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch(e => {
      if (e.response?.data?.length > 0) {
        return responseFormatter(false, null, e.response.data)
      } else {
        return responseFormatter(false, null, e.response.data)
      }
    });
}

export const getReq = async (url) => {
  const route_url = ENV.BASE_URL + url;
  return await axios.get(route_url,
    {
      headers:
      {
        Accept: "application/json",
      },
    })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch(e => {
      if (e.response.data.length > 0) {
        return responseFormatter(false, null, e.response.data)
      } else {
        return responseFormatter(false, null, e.response.data)
      }
    });
}

export const getImageUrl = async (data) => {
  var image = false

  await postApiReq("admin/upload-single-assets", data)
    .then((res) => {
      if (res.status) {
        image = res.data.data
      }
      else {
        image = false
      }
    }
    )
    .catch((e) => {
      console.log(e)
    })

  return image
}

export const getMultipleImageUrl = async (data) => {

  var image = false
  await postApiReq("admin/upload-multiple-assets", data)
    .then((res) => {
      if (res.status) {
        image = res.data.data
      }
      else {
        image = false
      }
    }
    )
    .catch((e) => {
      console.log(e)
    })

  return image
}

export const deleteApiReq = async (url) => {
  var USER = null
  if (Cookies.get('user')) {
    USER = JSON.parse(Cookies.get('user'))
  }
  const route_url = ENV.BASE_URL + url;
  return await axios.delete(route_url,
    {
      headers: USER?.token ? {
        Accept: "application/json",
        Authorization: `Bearer ${USER.token}`,
      } :
        {
          Accept: "application/json",
        },
    })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch(e => {
      if (e.response.status === 403) {
        localStorage.removeItem('user');
        window.location.href = '/'
      }
      else
        if (e.response.data?.length > 0) {
          return responseFormatter(false, null, e.response.data)
        } else {
          return responseFormatter(false, null, e.response.data)
        }
    });
}