import React, { useState } from 'react';
import { useFormik, FormikProvider } from 'formik';
import { Stack, TextField, FormControl, Grid, Typography, Button, Paper } from '@mui/material';
import { postApiReq } from 'src/utils/ApiHandler';
import { useDispatch } from 'react-redux';
import { showToast } from 'src/redux/action';

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    email: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    postApiReq(`admin/forgot-password`, form)
      .then((res) => {
        if (res.status) {
          dispatch(showToast({ type: 'success', message: 'Reset password link send to E-mail' }));
        }
        else {
          dispatch(showToast({ type: 'error', message: res.error }));
        }
      })
  }

  return (
    <Paper variant="outlined" square>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={5}>
          <FormControl>
            <Typography mt={15} sx={{ fontSize: '40px', fontWeight: '600' }}>
              Forgot Password
            </Typography>
            <Stack mt={4} spacing={3}>
              <TextField
                fullWidth
                autoComplete="username"
                type="email"
                label="Email address"
                name="email"
                id="email"
                value={form.email}
                onChange={(e) => setForm({ ...form, email: e.target.value })}
              />
            </Stack>
            <Button style={{ marginTop: '25px' }} variant="contained" onClick={handleSubmit}>
              Send Link
            </Button>
            <Button style={{ marginTop: '10px' }} variant="contained" href="/login">
              Return to login
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
};
