import React, { useContext, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import Loader from '../../../pages/popup/Loader';
import {
  Card,
  Stack,
  Link,
  Container,
  Typography,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Page from 'src/components/Page';
import { LoadingButton } from '@mui/lab';
import { getReq, postReq } from 'src/utils/ApiHandler';
import { AuthContext } from 'src/utils/AuthContext';
import { setUserDetail, showToast } from 'src/redux/action';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie'
import { LoginValidation } from 'src/utils/Auth';
import { isYupError, parseYupError } from '../../../utils/Yup';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function LoginForm() {
  const navigate = useNavigate();
  let sign = Cookies.get('sign');
  const dispatch = useDispatch();
  const [errors, setErrors] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { setUser } = useContext(AuthContext);
  const [form, setForm] = useState({
    email: '',
    password: ''
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const connectMetaMaskWallet = async (data) => {
    setIsLoading(true);
    /**check metamask install or not  */
    if (Boolean(ethereum && ethereum.isMetaMask)) {
      try {
        // Will open the MetaMask UI
        await ethereum
          .request({ method: "eth_requestAccounts" })
          .then((res) => {
            getReq(`admin/authenticate/${data.id}/${res[0]}`)
              .then((authResponse) => {
                if (authResponse.status) {
                  if (authResponse.data.status === 2) {
                    Cookies.remove('user');
                    Cookies.remove('user-data');
                    navigate('/login')
                    setIsLoading(false);
                    dispatch(showToast({
                      type: "error",
                      message: `Connect with Admin's Wallet Address`,
                    }))

                  } else {
                    ethereum
                      .request({
                        method: "personal_sign",
                        params: [`${authResponse.data.data}`, res[0]],
                      })
                      .then((sigRes) => {
                        if (sigRes) {
                          Cookies.set('sign', sigRes)
                          // dispatch(closeWalletModal());
                          postReq(`admin/authenticate`, {
                            address: res[0],
                            signature: sigRes,
                          })
                            .then((authTokenRes) => {
                              if (authTokenRes.status) {
                                Cookies.set(
                                  "user", JSON.stringify({ token: authTokenRes.data.data.token }),

                                  { expires: 1 }
                                );
                                Cookies.set('user-data', JSON.stringify({ type: data.type, isUserLogin: true, name: data.name, email: data.email, id: data.id }))
                                Cookies.set('user', JSON.stringify({ token: data.token }))
                                setUser((prev) => {
                                  return {
                                    ...prev,
                                    type: data.type,
                                    token: data.token,
                                    email: data.email,
                                    name: data.name,
                                    user: data,
                                    isUserLogin: true
                                  }
                                })
                                navigate('/dashboard/app');
                                dispatch(showToast({ type: 'success', message: 'Login Successfully' }));
                                dispatch(
                                  showToast({
                                    type: "success",
                                    message: "Metamask wallet connect",
                                  })
                                );

                                // navigate('/dashboard/add-property');
                                // dispatch(setWalletId(res[0]));
                                setIsLoading(false);
                              } else {
                                dispatch(
                                  showToast({
                                    type: "error",
                                    message: authTokenRes.error,
                                  })
                                );
                                setIsLoading(false);
                              }
                            })
                            .catch((e) => {
                              setIsLoading(false);
                              dispatch(
                                showToast({
                                  type: "error",
                                  message: e?.authResponse?.data,
                                })
                              );
                            });
                        }
                      })
                      .catch((e) => {
                        setIsLoading(false);
                        dispatch(
                          showToast({ type: "error", message: e?.message })
                        );
                      });
                  }
                } else {
                  setIsLoading(false);
                  dispatch(
                    showToast({ type: "error", message: authResponse.error })
                  );
                }
              })
              .catch((e) => {
                setIsLoading(false);
                dispatch(
                  showToast({ type: "error", message: e?.authResponse?.data })
                );
              });
          });
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    } else {
      dispatch(showToast({ type: "error", message: "Metamask Not Install" }));
      setIsLoading(false);
    }

  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrors({});
      await LoginValidation.validate(form, {
        abortEarly: false,
      });
      postReq(`admin/signin`, form)
        .then((res) => {
          if (res.status) {
            if (res.data.data) {
              setIsLoading(false);
              connectMetaMaskWallet(res.data.data);

              // connectMetaMaskWallet();

              // dispatch(showToast({ type: 'success', message: 'Login successfully.' }));
            }
            else {
              dispatch(showToast({ type: 'error', message: res?.data?.error }));
            }
          }
          else {
            dispatch(showToast({ type: 'error', message: 'Something Went Wrong, Please Contact to System Administrator.' }));
          }
        })
        .catch((e) => {
          console.error(e);
        });
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.error('error');
      }
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    })
    setErrors({ ...errors, [name]: '' })
  }
  return (
    <RootStyle>
      <Loader isLoading={isLoading} />

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Login
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>
          <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                autoComplete="username"
                type="email"
                name="email"
                id="email"
                label="Email address"
                value={form.email}
                error={errors?.email ? 'error' : ''}
                onChange={handleChange}
              />
              {errors && <Box sx={{ color: 'red' }}>{errors.email}</Box>}
              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                name="password"
                id="password"
                value={form.password}
                error={errors?.password ? 'error' : ''}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {errors && <Box sx={{ color: 'red' }}>{errors.password}</Box>}
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              <FormControlLabel
                control={<Checkbox />}
                label="Remember me"
              />
              <Link component={RouterLink} variant="subtitle2" to="/forgot-password">
                Forgot password?
              </Link>
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleSubmit}
            >
              Login
            </LoadingButton>
          </form>
        </ContentStyle>
      </Container>

    </RootStyle>
  );
}
