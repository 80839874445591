import React, { useContext, useEffect, useState } from 'react';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Router from './routes';
import ThemeConfig from './theme';
import ScrollToTop from './components/ScrollToTop';
import './index.css';
import { AuthContext } from './utils/AuthContext';
import { useDispatch, useSelector } from "react-redux";
import { HIDE_TOAST } from './redux/action/actionConstants';
import Cookies from 'js-cookie';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
})

export default function App() {
  const dispatch = useDispatch();
  const { isUserLogin } = useContext(AuthContext);
  const [user, setUser] = useState({
    isUserLogin: false,
    token: '',
    user_id: '',
    email: '',
    name: '',
    type: ''
  })

  const { type, message, isVisible } = useSelector(
    (state) => state.toastReducer
  );

  useEffect(() => {
    if (Cookies.get('user-data')) {
      let USER = JSON.parse(Cookies.get('user-data'));
      setUser({ ...user, type: USER.type, email: USER.eamil, name: USER.name });
    }
  }, [])

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({
      type: HIDE_TOAST,
    });
  };
  return (
    <ThemeConfig>
      <Snackbar
        open={isVisible}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <ScrollToTop />
      <AuthContext.Provider
        value={{
          ...user,
          setUser
        }}>
        <Router />
      </AuthContext.Provider>
    </ThemeConfig>
  );
}