import React, { useState, useContext, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import {
  Box,
  Button,
  Grid,
  Container,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  styled,
  TableBody,
  Modal
} from '@mui/material';
import { getApiReq, postApiReq } from 'src/utils/ApiHandler';
import { showToast } from 'src/redux/action';
import Loader from './popup/Loader';
import moment from 'moment';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ENV } from 'src/utils/env';
import { useDispatch } from 'react-redux';


const UserBankApproval = () => {
  const [approvalList, setApprovalList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalIndex, setModalIndex] = useState(null);

  const dispatch = useDispatch();

  // modal 
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = (index) => {
    setOpen(true);
    setModalIndex(index);
  };

  const handleClose = () => setOpen(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 2,
  };
  // .............. 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const StyledTableCell = styled(TableCell)({
    backgroundColor: '#7b819d',
    boxShadow: 2,
    color: 'white',
    fontSize: '14px',
    padding: '5px 10px 5px 10px',
    textTransform: 'capitalize',
    fontWeight: '600'
  });
  const StyledBodyTableCell = styled(TableCell)({
    fontSize: '14px',
    padding: '15px 10px 15px 10px',
    fontWeight: '500'
  });

  const getApprovalList = () => {
    getApiReq('admin/get-approval-list').then((res) => {
      if (res.status) {
        if (res.data.status === 1) {
          setApprovalList(res.data.data);
        }
      }
    })
  }

  useEffect(() => {
    getApprovalList();
  }, [])

  const handleApprove = (e, id, approve) => {
    setIsLoading(true);
    e.preventDefault();
    const data = { id, approve };
    postApiReq('admin/request-approve', data).then((res) => {
      setIsLoading(false);
      if (res.status) {
        if (res.data.status === 1) {
          if (approve === 0) {
            dispatch(showToast({ type: 'success', message: 'Rejected Successfully' }));
            getApprovalList();
          }
          else {
            dispatch(showToast({ type: 'success', message: 'Approved Successfully' }));
            getApprovalList();
          }
        } else {
          dispatch(showToast({ type: 'error', message: 'Something Went Wrong' }));
        }
      } else {
        dispatch(showToast({ type: 'error', message: 'Something Went Wrong' }));
      }
    })
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <div>
        <Container maxWidth="xl">
          <Box
            sx={{ pb: 5, display: 'flex', marginLeft: '-25px', justifyContent: 'space-between' }}
          >
            <Typography variant="h4">Users Bank Approval</Typography>
            <Box sx={{ marginBottom: '0px' }}>
              {/* <TextField
                fullWidth
                color="primary"
                InputProps={{
                  style: {
                    height: '40px',
                    padding: '0 10px',
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search"
                variant="outlined"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setOffset(0);
                }}
              /> */}
            </Box>

          </Box>
          <Grid container spacing={3}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell style={{ width: '5%' }}>S No.</StyledTableCell>
                      {/* <StyledTableCell style={{ width: '10%' }}>Image</StyledTableCell> */}
                      <StyledTableCell style={{ width: '14%' }}>Account Holder Name</StyledTableCell>
                      <StyledTableCell style={{ width: '8%' }}>Bank Name</StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '15%' }}>
                        Account Number
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '10%' }}>
                        IFSC Code
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '10%' }}>
                        Created At
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '10%' }}>
                        Cheque
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '15%' }}>
                        Action
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {approvalList?.length > 0 ? (
                      approvalList
                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        ?.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            style={index % 2 ? { background: '#f0f1f4' } : { background: 'white' }}
                          >
                            <StyledBodyTableCell component="th" scope="row">
                              {index + 1}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell>
                              {row.account_holder_name}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell>
                              {row.bank_name}
                            </StyledBodyTableCell>

                            <StyledBodyTableCell align="center">
                              {row.account_no}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              {row.bank_ifsc_code}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              {moment(row.created_at).format('DD MMM YYYY')}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              {/* {row.bank_ifsc_code} */}

                              <Button onClick={() => handleOpen(index)}><RemoveRedEyeIcon /></Button>
                              <Modal
                                open={open && index === modalIndex}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box sx={style}>
                                  <img src={row.cheque_image ? `${ENV.BASE_URL}/bank_verification/${row.cheque_image}` : null} />
                                </Box>
                              </Modal>

                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              <Button onClick={(e) => handleApprove(e, row.id, 1)} sx={{ width: '80px', minWidth: '40px' }}>
                                Approve
                              </Button>
                              <Button onClick={(e) => handleApprove(e, row.id, 0)} sx={{ width: '70px', minWidth: '40px' }}>
                                Reject
                              </Button>

                              {/* <Button sx={{ width: '40px', minWidth: '40px' }}>
                                <EditIcon
                                  onClick={() =>
                                    navigate(`/dashboard/edit-property/${row.property_id}`)
                                  }
                                />
                              </Button>
                              <Button sx={{ width: '40px', minWidth: '40px' }}>
                                <DeleteIcon
                                  onClick={(e) =>
                                    handleDeleteProperty(e, row.property_id)
                                  }
                                />
                              </Button> */}

                            </StyledBodyTableCell>

                          </TableRow>
                        ))
                    ) : (
                      <StyledBodyTableCell colSpan={9}>
                        <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                          No data found
                        </Typography>
                      </StyledBodyTableCell>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Container>
        {/* <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={propertyData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </div>
    </>
  )
}

export default UserBankApproval