import { SET_USER_DETAIl, } from "../action/actionConstants";

const initialState = {
  email: null,
  name: null,
  isUserLogin: null,
  image: null

};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DETAIl:
      return action.payload
        ;
    default:
      return state;
  }
};

export default UserReducer;
