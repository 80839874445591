import { combineReducers } from 'redux';
import CategoryReducer from './reducers/CategoryReducer';
import ToastReducer from "./reducers/ToastReducer";
import UserReducer from './reducers/UserReducer';

const rootReducer = combineReducers({
  toastReducer: ToastReducer,
  categoryReducer: CategoryReducer,
  userReducer:UserReducer,
});

export default rootReducer;

