import { Icon } from '@iconify/react';
import DnsIcon from '@mui/icons-material/Dns';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PaidIcon from '@mui/icons-material/Paid';
import SavingsIcon from '@mui/icons-material/Savings';

const sidebarConfig = [
  {
    title: 'Properties',
    path: '/dashboard/app',
    icon: <DnsIcon />
  },
  {
    title: "Whitelisting Requests",
    path: '/dashboard/users',
    icon: <PeopleAltIcon />
  },
  {
    title: "Transactions",
    path: '/dashboard/transactions',
    icon: <AccountBalanceWalletIcon />
  },
  {
    title: "Settings",
    path: '/dashboard/setting',
    icon: <SettingsIcon />
  },
  {
    title: "User Bank Approval",
    path: "/dashboard/user-bank-approval",
    icon: <PersonAddIcon />
  },
  {
    title: "Orders",
    path: "/dashboard/orders",
    icon: <LibraryBooksIcon />
  },
  {
    title: "Customer Bank Details",
    path: "/dashboard/customer-bank-details",
    icon: <AccountBalanceIcon />
  },
  {
    title: "Request Payment",
    path: "/dashboard/payment-request",
    icon: <PaidIcon />
  },
  {
    title: "Vault",
    path: "/dashboard/vault",
    icon: <SavingsIcon />
  }

];

export default sidebarConfig;


