import React, { useState, useContext, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import TocIcon from '@mui/icons-material/Toc';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Box,
  Grid,
  Button,
  Container,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  styled,
  InputAdornment,
  SvgIcon,
  TextField,
  Tooltip
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { deleteApiReq, getApiReq, getReq, postApiReq, postReq } from 'src/utils/ApiHandler';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showToast } from 'src/redux/action';
import { AuthContext } from 'src/utils/AuthContext';
import Loader from './popup/Loader';
import moment from 'moment';
import Cookies from 'js-cookie';

const StyledTableCell = styled(TableCell)({
  backgroundColor: '#7b819d',
  boxShadow: 2,
  color: 'white',
  fontSize: '14px',
  padding: '5px 10px 5px 10px',
  textTransform: 'capitalize',
  fontWeight: '600'
});
const StyledBodyTableCell = styled(TableCell)({
  fontSize: '14px',
  padding: '15px 10px 15px 10px',
  fontWeight: '500'
});

export default function Orders() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [propertyData, setPropertyData] = useState();
  const [orders, setOrders] = useState([])
  const [search, setSearch] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { user, type } = useContext(AuthContext);
  let sign = Cookies.get('sign');
  let userId = Cookies.get('user-data')
  userId = userId ? JSON.parse(userId) : null;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const getOrders = async () => {
    getApiReq('admin/get-orders').then((res) => {
      setIsLoading(false);
      if (res.status) {
        if (res.data.status === 1) {
          setOrders(res?.data?.data)
        } else {
          dispatch(showToast({ type: 'error', message: res.data.error }));
        }
      } else {
        dispatch(showToast({ type: 'error', message: res.error }));
        setIsLoading(false);
      }
    })

  }

  useEffect(() => {
    getOrders();
  }, [])

  return (
    <>
      <Loader isLoading={isLoading} />
      <div>
        <Container maxWidth="xl">
          <Box
            sx={{ pb: 5, display: 'flex', marginLeft: '-25px', justifyContent: 'space-between' }}
          >
            <Typography variant="h4">Orders</Typography>
            <Box sx={{ marginBottom: '0px' }}>
              {/* <TextField
                fullWidth
                color="primary"
                InputProps={{
                  style: {
                    height: '40px',
                    padding: '0 10px',
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search"
                variant="outlined"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setOffset(0);
                }}
              /> */}
            </Box>

          </Box>
          <Grid container spacing={3}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell style={{ width: '8%' }}>S No.</StyledTableCell>
                      {/* <StyledTableCell style={{ width: '10%' }}>Image</StyledTableCell> */}
                      <StyledTableCell style={{ width: '7%' }}>Property Name</StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '15%' }}>
                        Fractions
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '10%' }}>
                        Payment Status
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '10%' }}>
                        Gas Used
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '10%' }}>
                        Transfer Status
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '10%' }}>
                        Created At
                      </StyledTableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders?.length > 0 ? (
                      orders
                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        ?.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            style={index % 2 ? { background: '#f0f1f4' } : { background: 'white' }}
                          >
                            <StyledBodyTableCell component="th" scope="row">
                              {index + 1}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell>
                              {row.title}
                            </StyledBodyTableCell>

                            <StyledBodyTableCell align="center">
                              {row.fractions}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              {row.payment_status}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              {row.calculated_gas_fee}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              {row.nft_transfer}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              {moment(row.created_at).format('DD MMM YYYY')}
                            </StyledBodyTableCell>
                          </TableRow>
                        ))
                    ) : (
                      <StyledBodyTableCell colSpan={9}>
                        <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                          No data found
                        </Typography>
                      </StyledBodyTableCell>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Container>
        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={orders?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
}
