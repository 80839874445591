import React, { useState, useContext, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import {
  Box,
  Button,
  Grid,
  Container,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  styled,
  TableBody,
  Modal,
  Autocomplete,
  TextField
} from '@mui/material';
import { getApiReq, postApiReq } from 'src/utils/ApiHandler';
import { showToast } from 'src/redux/action';
import Loader from './popup/Loader';
import moment from 'moment';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ENV } from 'src/utils/env';
import { useDispatch } from 'react-redux';


const PaymentRequest = () => {
  const [approvalList, setApprovalList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [propertiesName, setPropertiesName] = useState([]);

  const dispatch = useDispatch();

  // modal 
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 2,
  };
  // .............. 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const StyledTableCell = styled(TableCell)({
    backgroundColor: '#7b819d',
    boxShadow: 2,
    color: 'white',
    fontSize: '14px',
    padding: '5px 10px 5px 10px',
    textTransform: 'capitalize',
    fontWeight: '600'
  });

  const StyledBodyTableCell = styled(TableCell)({
    fontSize: '14px',
    padding: '15px 10px 15px 10px',
    fontWeight: '500'
  });

  const getRequestPaymentList = () => {
    getApiReq('admin/get-fraction-payment-request').then((res) => {
      if (res.status) {
        if (res.data.status === 1) {
          setApprovalList(res.data.data);
          setPropertiesName([]);
          res.data.data.forEach((name) => setPropertiesName(propertiesName => [...propertiesName, name.title]));
        }
      }
    })
  }

  useEffect(() => {
    getRequestPaymentList();
  }, [])

  const handleApprove = (e, id, approve) => {
    setIsLoading(true);
    e.preventDefault();
    const data = { id, approve };
    postApiReq('admin/approve-fraction-payment-request', data).then((res) => {
      setIsLoading(false);
      if (res.status) {
        if (res.data.status === 1) {
          if (approve === 0) {
            dispatch(showToast({ type: 'success', message: 'Rejected Successfully' }));
            getApprovalList();
          }
          else {
            dispatch(showToast({ type: 'success', message: 'Approved Successfully' }));
            getRequestPaymentList();
          }
        } else {
          dispatch(showToast({ type: 'error', message: 'Something Went Wrong' }));
        }
      } else {
        dispatch(showToast({ type: 'error', message: 'Something Went Wrong' }));
      }
    })
  }



  return (
    <>
      <Loader isLoading={isLoading} />
      <div>
        <Container maxWidth="xl">
          <Box sx={{ pb: 5, display: 'flex', marginLeft: '-25px', justifyContent: 'space-between' }}>
            <Typography variant="h4">Payment Request</Typography>
            <Box sx={{ marginBottom: '0px' }}>
            </Box>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={propertiesName}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Property Name" />}
            />
          </Box>

          <Grid container spacing={3}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell style={{ width: '5%' }}>S No.</StyledTableCell>
                      {/* <StyledTableCell style={{ width: '10%' }}>Image</StyledTableCell> */}
                      <StyledTableCell style={{ width: '14%' }}>Account Holder Name</StyledTableCell>
                      <StyledTableCell style={{ width: '14%' }}>Property Name</StyledTableCell>
                      <StyledTableCell style={{ width: '8%' }}>Bank Name</StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '15%' }}>
                        Account Number
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '10%' }}>
                        Transaction Details
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '10%' }}>
                        Total Fractions
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '10%' }}>
                        Total Amount
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '10%' }}>
                        Created At
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '15%' }}>
                        Action
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {approvalList?.length > 0 ? (
                      approvalList
                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        ?.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            style={index % 2 ? { background: '#f0f1f4' } : { background: 'white' }}
                          >
                            <StyledBodyTableCell component="th" scope="row">
                              {index + 1}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell>
                              {row.sender_name}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell>
                              {row.title}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell>
                              {row.sender_bank_name}
                            </StyledBodyTableCell>

                            <StyledBodyTableCell align="center">
                              {row.sender_bank_account_no}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              {row.transaction_description}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              {row.fractions}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              {row.total_amount}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              {moment(row.created_at).format('DD MMM YYYY')}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              <Button onClick={(e) => handleApprove(e, row.id, 1)} sx={{ width: '80px', minWidth: '40px' }}>
                                Approve
                              </Button>
                              <Button onClick={(e) => handleApprove(e, row.id, 0)} sx={{ width: '70px', minWidth: '40px' }}>
                                Reject
                              </Button>
                            </StyledBodyTableCell>

                          </TableRow>
                        ))
                    ) : (
                      <StyledBodyTableCell colSpan={9}>
                        <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                          No data found
                        </Typography>
                      </StyledBodyTableCell>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Container>
        {/* <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={propertyData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </div>
    </>
  )
}

export default PaymentRequest;