import React, { useState, useContext, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { Calendar } from '@material-ui/pickers'
import {
  Box,
  Grid,
  Button,
  Container,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  styled,
  InputAdornment,
  SvgIcon,
  TextField,
  Tooltip
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { deleteApiReq, getApiReq, getReq, postApiReq, postReq } from 'src/utils/ApiHandler';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showToast } from 'src/redux/action';
import { AuthContext } from 'src/utils/AuthContext';
import Loader from './popup/Loader';
import moment from 'moment';
import Cookies from 'js-cookie';

const StyledTableCell = styled(TableCell)({
  backgroundColor: '#7b819d',
  boxShadow: 2,
  color: 'white',
  fontSize: '14px',
  padding: '5px 10px 5px 10px',
  textTransform: 'capitalize',
  fontWeight: '600'
});
const StyledBodyTableCell = styled(TableCell)({
  fontSize: '14px',
  padding: '15px 10px 15px 10px',
  fontWeight: '500'
});

export default function Transactions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [propertyData, setPropertyData] = useState();
  const [transaction, setTransaction] = useState([])
  const [search, setSearch] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { user, type } = useContext(AuthContext);
  let sign = Cookies.get('sign');
  let userId = Cookies.get('user-data')
  userId = userId ? JSON.parse(userId) : null;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const getTransactionLog = async () => {
    getApiReq('admin/get-transactions').then((res) => {
      if (res.status) {
        setIsLoading(false);
        console.log(res.data.data);
        if (res.data.status === 1) {
          setTransaction(res?.data?.data)
        } else {
          dispatch(showToast({ type: 'error', message: res.data.error }));
        }
      } else {
        dispatch(showToast({ type: 'error', message: res.error }));
        setIsLoading(false);
      }
    })

  }

  useEffect(() => {
    getTransactionLog();
  }, [])


  return (
    <>
      <Loader isLoading={isLoading} />
      <div>
        <Container maxWidth="xl">
          <Box
            sx={{ pb: 5, display: 'flex', marginLeft: '-25px', justifyContent: 'space-between' }}
          >
            <Typography variant="h4">Transactions</Typography>
            <Box sx={{ marginBottom: '0px' }}>
            </Box>

          </Box>
          <Grid container spacing={3}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell style={{ width: '8%' }}>S No.</StyledTableCell>
                      {/* <StyledTableCell style={{ width: '10%' }}>Image</StyledTableCell> */}
                      <StyledTableCell style={{ width: '7%' }}>Property Name</StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '15%' }}>
                        Event
                      </StyledTableCell>
                      {/* <StyledTableCell align="center" style={{ width: '10%' }}>
                        From
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '10%' }}>
                        To
                      </StyledTableCell> */}
                      <StyledTableCell align="center" style={{ width: '10%' }}>
                        Token Id
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '10%' }}>
                        Txn Hash
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '10%' }}>
                        Created At
                      </StyledTableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transaction?.length > 0 ? (
                      transaction
                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        ?.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            style={index % 2 ? { background: '#f0f1f4' } : { background: 'white' }}
                          >
                            <StyledBodyTableCell component="th" scope="row">
                              {index + 1}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell>
                              {row.title}
                            </StyledBodyTableCell>

                            <StyledBodyTableCell align="center">
                              {row.transfer_event}
                            </StyledBodyTableCell>
                            {/* <StyledBodyTableCell align="center">
                              {row.transfer_from}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              {row.transfer_to}
                            </StyledBodyTableCell> */}
                            <StyledBodyTableCell align="center">
                              {row?.token_id}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell style={{ 'cursor': 'pointer' }} onClick={() => { window.open(`https://testnet.bscscan.com/tx/${row?.transaction_hash}`) }} align="center">
                              {row?.transaction_hash?.slice(0, 30)}
                            </StyledBodyTableCell>
                            <StyledBodyTableCell align="center">
                              {moment(row.created_at).format('DD MMM YYYY')}
                            </StyledBodyTableCell>
                          </TableRow>
                        ))
                    ) : (
                      <StyledBodyTableCell colSpan={9}>
                        <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                          No data found
                        </Typography>
                      </StyledBodyTableCell>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Container>
        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={transaction?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
}
